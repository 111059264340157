<template>
  <base-section id="who-we-are">
    <!-- <div class="ma-10"> -->
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <base-section-heading align="center" tag="h2" title="Who We Are">
          <div align="justify" class="mx-3 black--text">
            We are a product development and technology consulting firm with
            focus on building data driven modern apps with data security at its
            core. On the product side our data security and credential
            management platform “First Vault” caters to retail, enterprise and
            government agencies alike. On the tech consulting side, we have a
            niche in the finance domain and our offerings cater to all
            industries alike and help them accelerate their digital
            transformation. <br /><br />

            We specialize in providing data security and governance, technology
            consulting, analyzing business requirements, data warehousing,
            business intelligence, analytics and third party integration.
          </div>
        </base-section-heading></v-col
      >
      <div v-if="this.$vuetify.breakpoint.mdAndUp" class="right-side">
        <div class="div-array-grid">
          <div
            v-for="(title, index) in features"
            :key="index"
            class="div-array-item"
          >
            <v-img
              :src="require(`@/assets/valueimg-${index + 1}.png`)"
              alt="Image"
              max-height="150"
              max-width="150"
              class="blue-filter"
            />
            <span class="mx-4">{{ title }}</span>
          </div>
        </div>
      </div>
    </v-row>
    <v-row v-if="this.$vuetify.breakpoint.smAndDown">
      <div class="div-array-grid">
        <div
          v-for="(title, index) in features"
          :key="index"
          class="div-array-item"
        >
          <v-img
            :src="require(`@/assets/valueimg-${index + 1}.png`)"
            alt="Image"
            max-height="150"
            max-width="150"
            class="blue-filter"
          />
          <span class="mx-4">{{ title }}</span>
        </div>
      </div>
    </v-row>
  </base-section>
</template>

<script>
export default {
  name: "WhoWeAre",

  data: () => ({
    features: [
      "Integrity",
      "Transparency",
      "Teamwork",
      "Trust and Accountability",
    ],
  }),
};
</script>
<style scoped>
.blue-filter {
  filter: invert(90%);
}

.right-side {
  width: 50%;
  /* display: grid; */
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.div-array-grid {
  display: flex;
  flex-wrap: wrap;
}

.div-array-item {
  padding: 10px;
  margin: 10px;
  flex: 0 0 45%;
}
</style>
